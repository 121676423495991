<template>
  <modal
    :show.sync="isShowModal"
    modal-classes="modal-secondary"
    :backdrop-close="true"
    size="lg"
    @close="close()"
    @show="show()"
  >
    <h6 slot="header" class="modal-title text-uppercase">{{ titleValue }}</h6>

    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-6">
          <base-input
            v-model="formGroup.first_name.value"
            :rules="formGroup.first_name.rule"
            label="First Name"
            name="First Name"
            placeholder="Enter FirstName"
          ></base-input>
        </div>
        <div class="col-md-6">
          <base-input
            v-model="formGroup.last_name.value"
            :rules="formGroup.last_name.rule"
            label="Last Name"
            name="Last Name"
            placeholder="Enter LastName"
          ></base-input>
        </div>
        <div class="col-md-6">
          <base-input
            v-model="formGroup.email.value"
            :rules="formGroup.email.rule"
            label="Email Name"
            name="Email"
            type="email"
            placeholder="Enter Email"
          ></base-input>
        </div>

        <div class="col-md-6">
          <base-input
            v-model="formGroup.password.value"
            :rules="formGroup.password.rule"
            label="Password"
            name="Password"
            type="password"
            placeholder="Enter password"
          ></base-input>
        </div>

        <div class="col-md-6">
          <base-input
            v-model="formGroup.phone.value"
            :rules="formGroup.phone.rule"
            label="Phone"
            name="Phone"
            placeholder="Enter Phone"
          ></base-input>
        </div>

        <div v-if="role(formGroup.user_type.value)" class="col-md-6">
          <base-selectbox
            v-model="formGroup.user_type.value"
            :rules="formGroup.user_type.rule"
            :options="source.roles"
            :disabled="role(formGroup.user_type.value)"
            placeholder="Select Role"
            label="Role"
            opt-label="label"
            opt-value="value"
            name="role"
            size="small"
          ></base-selectbox>
        </div>
        <div v-else class="col-md-6">
          <base-selectbox
            v-model="formGroup.user_type.value"
            :options="source.status"
            placeholder="Select Role"
            label="Role"
            opt-label="label"
            opt-value="value"
            name="role"
            size="small"
          ></base-selectbox>
        </div>
      </div>
    </validation-observer>
    <template v-slot:footer>
      <base-button type="primary" :loading="loading" @click="saveAirport">
        Submit
      </base-button>
      <base-button
        class="ml-auto"
        type="primary"
        :outline="true"
        @click="close()"
      >
        Close
      </base-button>
    </template>
  </modal>
</template>

<script>
import { FormGroup } from "@/core/models";
import { membersService, notificationService } from "@/core/services";

export default {
  components: {},
  props: {
    showAddUserModalForm: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    edite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalState: false,
      formGroup: new FormGroup({
        first_name: [null, { required: true }],
        last_name: [null, { required: true }],
        email: [null, { required: true }],
        password: [null, { required: this.item.id ? false : true, min: 6 }],
        user_type: [null, { required: true }],
        phone: [null, { required: true }],
      }),
      source: {
        status: [
          { label: "Admin", value: 1 },
          { label: "Pilot", value: 2 },
          { label: "Customer Support", value: 3 },
        ],
        roles: [
          { label: "Member", value: 4 },
          { label: "Shadow Member", value: 5 },
        ],
        vat_status: [
          { label: "Verified", value: 2 },
          { label: "Unverified", value: 1 },
          { label: "UnIdentified", value: 0 },
        ],
      },
      loading: false,
      titleValue: {
        type: String,
        default: "",
      },
    };
  },
  computed: {
    isShowModal: {
      get() {
        return this.showAddUserModalForm;
      },
      set(value) {
        this.modalState = value;
      },
    },
  },
  methods: {
    role(value) {
      if (value) {
        let value1 = this.source.status.some(
          (status) => status["value"] === value
        );

        return value1 ? false : true;
      }
      return false;
    },
    async saveAirport() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        // x = this.formGroup.value;
        // if (!x.pass) {
        //   delete x.pass;
        // }
        // console.log();

        this.loading = true;
        if (this.item.id) {
          let value = this.formGroup.value;
          if (!value.password) {
            delete value.password;
          }
          membersService
            .editUser(this.item.id, value)
            .then((res) => {
              notificationService.success(res.message);
              this.loading = false;
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          let value = { is_active: true };
          value = { ...value, ...this.formGroup.value };
          console.log(value);
          membersService
            .saveUser(value)
            .then((res) => {
              notificationService.success(res.message);
              this.close();
            })
            .finally(() => (this.loading = false));
        }
      }
    },
    show() {
      if (this.item.id) {
        this.titleValue = "Edit User";

        this.formGroup.password.rule.required = false;

        const formGroup = {
          first_name: this.item.first_name,
          last_name: this.item.last_name,
          email: this.item.email,
          password: this.item.password,
          phone: this.item.phone,
          user_type: this.item.user_type_id,
        };

        this.formGroup.populate(formGroup);
      } else {
        this.titleValue = "Add User";
      }
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
      this.$refs.formValidator.reset();
      this.formGroup.reset();
      //console.log(this.formGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-separator {
  border-bottom: 1px solid #968888;
  margin-bottom: 1.5em;
  margin-top: 1em;
}
</style>
